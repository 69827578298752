import logo from './logo.svg';
import './App.css';
import LocationPage from './components/LocationPage';
import AlternativeLocationPage from './components/AlternativeLocationPage';
import BingMapLocationPage from './components/BingMapLocationPage';
import IPAPILocationPage from './components/IPAPILocationPage';
function App() {
  return (
    <div className="App">

      <h1>This is Using IP Address to get User Country Code</h1>
      <IPAPILocationPage />

      {/* <h1>This is Google</h1>
      <LocationPage />
      ============ */}
      {/* <h1>This is Opencagedata</h1>

      <AlternativeLocationPage />
      ============
      <h1>This is Bing Map</h1>

      <BingMapLocationPage /> */}

    </div>
  );
}

export default App;
