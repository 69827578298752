import React, { useState, useEffect } from 'react';
import axios from 'axios';

const IPAPILocationPage = () => {
  const [location, setLocation] = useState({ ip: null, countryCode: null });
  const [error, setError] = useState(null);

  useEffect(() => {
    axios.get('https://ipapi.co/json/')
      .then(response => {
        const { ip, country } = response.data;
        console.log(`IP: ${ip}, Country code: ${country}`);
        setLocation({ ip, countryCode: country });
      })
      .catch(error => {
        console.error(error);
        setError(error.toString());
      });
  }, []);

  return (
    <div>
      {error && <div>Error: {error}</div>}
      {location.ip && <div>IP: {location.ip}</div>}
      {location.countryCode && <div>Country code: {location.countryCode}</div>}
    </div>
  );
};

export default IPAPILocationPage;